.online{
    color:#6bc678;
}
.away{
    color: #e56a34;
}

.buttonBar{
    display: flex;
    justify-content: space-between;
}