.container{
    display: flex;
    flex-direction:column;
    margin-top: auto;
    margin-bottom:90px;
}

.myMessage {
    align-self: flex-end;

    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
}

.myDate{
    text-align:right;
    margin-top:5px;
}

.message{
    align-self:flex-start;
    border-radius:20px;
    margin-bottom:10px;
    margin-right:20px;
}

.light{
    background:#f2f2f2;
}

.myLight{
    background: #effdde;
}

.myDark{
    background: #21141f;
}

.dark{
    background: #2f272c;
}

.date{
    text-align:left;
    margin-top:5px;
}

.loadMoreContainer{
    display: flex;
    justify-content: center;
}

.viewed{
    font-size: 80%;
    opacity: 0.5;
    display: flex;
    justify-content: center;
}

.viewStatusText{
    margin-left: 5px;
}

.viewedIcon{
    color:#6bc678;
}



.typing:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 900ms infinite;
    animation: ellipsis steps(4,end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0;
}

@keyframes ellipsis {
    to {
        width: 20px;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 20px;
    }
}