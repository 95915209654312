.w98{
    width: 98%;
    height: auto;
}

.linkColor{
    color:#9D746E;
}

.videoContainer {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    cursor: pointer;
}
.videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videoContainer img{
    position: absolute;
    display: block;
}

.videoTitleBlock{
    position: absolute;
    width: 100%;
    bottom: -1px;
    font-size: 120%;
    height: 2em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: #4986cc;
    color: white;
    text-align: center;
}

.ytbPreviewImg{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.videoMessageContainer {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}

.tikTokContainer {
    position: relative;
    width: 325px;
    height: 758px;
    margin: 0 auto;
    overflow: hidden;
    cursor: pointer;
}

.tikTokContainerPadding{
    padding: 0 5px!important;
    border-radius: 5px;
/*    background-color: var(--main-bg-color); */
}
.tikTokMessagePadding{
    border-radius: 5px;
/*    background-color: var(--module-bg-color);
    border: 5px solid var(--main-bg-color); */
    border-top-width: 18px;
    cursor: default!important;
}
.tikTokContainer blockquote{
    margin-top: 0!important;
    margin-bottom: 0!important;
}
.tikTokPreviewImg{
    width: 100%;
    margin: 18px auto;
    height: 575px;
}

.tiktokTitleBlock{
 /*   background-color: var(--main-bg-color); */
    width: 100%;
    bottom: 0;
    font-size: 120%;
    max-height: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tiktokMessageBlock{
    position: absolute;
   /* background-color: var(--main-bg-color);*/
    width: 100%;
    bottom: 0;
    font-size: 120%;
    height: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding-top: 20px;
}

.audioContainer {
    position: relative;
    height: 450px;
    cursor: pointer;
  /*  border: var(--main-bg-color) solid 5px; */
}

.audioPlayButton{
    width: 20px;
    height: 20px;
    padding-left: 5px;
}

.audioTitleBlock{
    display: flex;
    position: absolute;
    align-self: center;
    margin-left: 1%;
    margin-right: 1%;
    border-radius: 10px;
    width: 98%;
    top: 85%;
    font-size: 120%;
    height: 10%;
/*    background-color: var(--main-bg-color); */
    padding-top: 10px;
}

.audioTitleIconBlock{
    flex-basis: 35px;
    flex-grow: 0;
}

.audioTitleTextBlock{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
}

.center{
    text-align: center!important;
}

.instaTitleBlock{
    margin: 0 5px;
/*    border: var(--main-bg-color) solid 5px; */
    text-align: left;
    display: flex;
}

.instaLinkContainer{
    margin: auto 5px;
}
.instaLink{
    line-height: 0;
}
.instaLinkContainer a{
/*    color: var(--navLinkIcon-color); */
    text-decoration: none;
}
.instaIcon{
    height: 2em;
}

.imageProcessingContainer{
    width:250px;
    height: 200px;
    background-size: cover;
    position: relative;
}

.loadingSpinner{
    height: 100px;
    width: 100px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}


.yaMusic{
    width: 100%;
    height: 180px;
    background-image:linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    cursor: pointer;
    margin-bottom: 4.5px;
}

.yaMusic .cover{
    width: 148px;
    height: 148px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 150%;
    background-color: rgb(255, 219, 77);
}

.container{
    width: 80%;
    height: 148px;
    margin: auto 7px;
}

.title1{
    color: #f2f2f2;
    font-size: 24px;
}

.title2{
    color:rgba(255, 255, 255, 0.5);
    font-size: 14px;
}
.controls{
    margin-top: 10px;
    display: flex;
}

.playButton {
    background-color: rgb(255, 219, 77);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.likeButton{
    margin-left: 10px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: rgba(255, 255, 255, 0.2);
    border-style: solid;
    border-width: 1px;
}

.progress{
    display: flex;
    margin-top: 5px;

}
.hr{
    height: 4px;
    background-color: rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);

}

.hrContainer{
    height: 15px;
    width: 85%;
    margin-top: 6px;
}

.indicator{
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: rgb(255, 219, 77);
}