.unreadLight{
    background-color: #e4e5e1;
}

.unreadDark{
    background-color: #281b20;
}

.messagePreview{
    border-radius: 5px;
}