.activityContainer{
    display: flex;
    margin-bottom: 10px;
}

.activityCost{
    display: flex;
    flex: 0 0 3em;
    justify-content: right;
    margin-right: 5px;
}

.nameContainer{
    flex-grow: 1;
    display: flex;
    margin: auto;
    font-size: 18px;
}

.activityProgress{
    flex: 0 0 6em;
    text-align: right;
    margin-top: auto;
    margin-bottom: auto;
}