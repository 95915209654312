.gift{
    padding:5px;
    margin-top: 5px;
    margin-bottom: 5px;
    background: rgb(87,110,159);
    background: radial-gradient(circle,  rgba(103,180,128,1)0%,  rgba(87,110,159,1)100%)
}


.giftFooter{
    display: flex;
    width: 60px;
    margin-left: auto;
    margin-right: 0;
    justify-content: flex-end;
    background-color: #1da1f1;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}