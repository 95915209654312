:root {
    --background_page: #ebedf0;
}

body[scheme="space_gray"] {
    --background_page: #0a0a0a;
}

.Panel__in {
    background: var(--background_page);
}

.lastElement{
    margin-bottom: 50px;
}