.error{
    color: #cc0000;
}

.answerButtons{
    display: flex;
    justify-content: space-between;
}

.tags{
    margin-left: 10px;
}

.tag{
    cursor: pointer;
    margin-left: 2px;
}


.thumb{
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
    justify-content: center;
}

.thumbInner {
    display: flex;
    min-width: 0;
    overflow: hidden;
    position: relative;
}

.preview {
    display: block;
    width: auto;
    height: 100px;
}
.anonCost{
    display: flex;
    flex: 0 0 5em;
    justify-content: right;
    margin-right: 5px;
}