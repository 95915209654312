.socialNameContainer{
    cursor: pointer;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /*display: flex;
    align-items: center;
    justify-content: center;*/
    z-index: 98;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}