.actionBar{
    display: flex;
    justify-content: space-between;
}

.loadingName{
    height: 1em;
    width: 10em;
    background-color: grey;
    opacity: 0.2;
}

.loadingDate{
    margin-top: 2px;
    height: 0.8em;
    width: 8em;
    background-color: grey;
    opacity: 0.2;
}

.loadingText1{
    margin-top: 2px;
    height: 0.8em;
    width: 98%;
    background-color: grey;
    opacity: 0.2;
}
.loadingText2{
    margin-top: 2px;
    height: 0.8em;
    width: 80%;
    background-color: grey;
    opacity: 0.2;
}

.loadingText3{
    margin-top: 2px;
    height: 0.8em;
    width: 90%;
    background-color: grey;
    opacity: 0.2;
}

.answer {
    margin-left: 6% !important;
}

.comment{
    margin-left: 12%;
}

.tags{
   margin-left: 10px;
}

.loadMoreContainer{
    display: flex;
    justify-content: center;
}

.red{
    color: red!important;
}

.linkColor{
    color:#9D746E;
}